import { HotelSort } from '#types/hotel';

export default {
  defaultRoom: 1,
  defaultAdult: 1,
  defaultChild: [],
  defaultSort: 'recommendation',
  defaultPage: 1,
  defaultSortItems: Object.freeze([
    {
      id: HotelSort.RECOMMENDED,
      text: 'hotel.recommended',
    },
    // {
    //   id: HotelSort.TOP_REVIEW,
    //   text: 'hotel.topreviewed',
    // },
    {
      id: HotelSort.LOWEST_PRICE,
      text: 'global.highestprice',
    },
    {
      id: HotelSort.HIGHEST_PRICE,
      text: 'global.lowestprice',
    },
  ]),
  defaultSpecialRequestChecks: Object.freeze([
    {
      id: 'nonsmoking',
      text: 'bookingform.nonsmokingroom',
    },
    {
      id: 'smoking',
      text: 'bookingform.smokingroom',
    },
    {
      id: 'locker',
      text: 'bookingform.safetylocker',
    },
    {
      id: 'latecheck',
      text: 'bookingform.latecheckin',
    },
    {
      id: 'highfloor',
      text: 'bookingform.highfloor',
    },
    {
      id: 'lowfloor',
      text: 'bookingform.lowfloor',
    },
    {
      id: 'wheelchair',
      text: 'bookingform.wheelchairacc',
    },
    {
      id: 'largebed',
      text: 'bookingform.largebed',
    },
    {
      id: 'twinbed',
      text: 'bookingform.twinbed',
    },
  ]),
  defaultResultQuickFilters: Object.freeze({
    type: 'all',
    value: 0,
  }),
  defaultResultFilters: Object.freeze({
    price: [0, 0],
    stars: [],
    facilities: [],
    types: [],
  }),
};

export const SEARCH_HISTORY_LIMIT = 10; // 10 records
export const HOTEL_RESULT_ROWS = 25;
export const MIN_DISPLAY_REVIEW_RATING = 0;
export const MIN_DISPLAY_REVIEW_COUNT = 5;
export const MIN_DISPLAY_TRIPADV_REVIEW_RATING = 0;
export const MIN_DISPLAY_TRIPADV_REVIEW_COUNT = 5;
